// LicenseModal.tsx
import { useState, useEffect, useRef } from 'react';
import { useAntdConfig, useIntl, useModel } from '@umijs/max';
import { Form, Input, Modal, message, Alert, ConfigProvider, theme } from 'antd';
import { updateSettings, initialState as queryInitialState } from '@/services/api';
import { t } from '@/global';
import { checkHasAlgorithm } from '../RightContent/ThemeSwitch';
const { darkAlgorithm } = theme;

type LicenseModalProps = {
  once: boolean;
  trigger?: number;
};

export const LicenseModal: React.FC<LicenseModalProps> = (props) => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const [isLicenseModalVisible, setIsLicenseModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const intl = useIntl();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const defaultValue = initialState?.settings?.find((entry) => entry.key === 'LICENSE')?.value;

  const plainSetting = (text: string, defaultMessage = '') => t(`pages.Settings.License.${text}`, true, defaultMessage || text, intl) as string;

  useEffect(
    () => {
      const status_text = initialState?.license?.status_text;
      if (props.once) {
        if (status_text && status_text.indexOf('not set') >= 0) {
          setIsLicenseModalVisible(true);
        }
      } else {
        setIsLicenseModalVisible((props.trigger || 0) > 0);
      }
    },
    props.once ? [] : [props.trigger],
  );

  const onConfirm = async () => {
    try {
      try {
        await form.validateFields();
      } catch (e) {
        return;
      }
      const values = form.getFieldsValue(['LICENSE']);
      if (!values) {
        message.error(plainSetting('licenseCannotBeEmpty', 'License cannot be empty.'));
        return;
      }
      values.LICENSE = values.LICENSE.trim();
      setConfirmLoading(true);
      var failed = false;
      try {
        await updateSettings(values);
      } catch (e) {
        message.error('Failed to update license: ' + e, 6);
        failed = true;
      }
      const current = await queryInitialState();
      if (current) {
        await setInitialState((s) => ({
          ...s,
          ...current,
        }));
        if (current.license?.status_text) {
          if (!failed) message.error(current.license?.status_text);
        } else {
          if (!failed) message.success('Update license successfully.');
          timerRef.current = setTimeout(() => {
            setIsLicenseModalVisible(false);
          }, 300);
        }
      }
    } catch (e) {
      message.error('Failed to update license: ' + e, 6);
    }
    setConfirmLoading(false);
  };

  return (isLicenseModalVisible &&
    <Modal
      title={t('Enter License')}
      open={isLicenseModalVisible}
      confirmLoading={confirmLoading}
      onCancel={() => {
        if (timerRef.current) clearTimeout(timerRef.current);
        setIsLicenseModalVisible(false);
      }}
      onOk={onConfirm}
      maskClosable={false}
    >
      <Form form={form} initialValues={{ LICENSE: defaultValue }}>
        <Form.Item
          label={t('License')}
          name="LICENSE"
          rules={[
            {
              required: true,
              whitespace: true,
              message: plainSetting('licenseCannotBeEmpty', 'License cannot be empty.'),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const LicenseAlert: React.FC = () => {
  const { initialState } = useModel('@@initialState');

  const intl = useIntl();
  const plain = (text: string, defaultMessage = '') => t(`component.LicenseAlert.${text}`, true, defaultMessage || text, intl) as string;
  const antdConfig = useAntdConfig();
  const isDark = checkHasAlgorithm(antdConfig, darkAlgorithm);

  const getAlert = (type: 'success' | 'info' | 'warning' | 'error', message: string) => {
    return <ConfigProvider
      theme={{
        token: {
          colorErrorBg: isDark ? '#7c2826' : '#FDD3D2',
        },
      }}
    >
      <Alert message={message} type={type} banner showIcon />
    </ConfigProvider>;
  };
  const status_text = initialState?.license?.status_text;
  if (status_text) {
    return getAlert('error', plain(status_text + '.'));
  }
  return <></>;
};
